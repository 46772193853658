import * as React from 'react';
import { Button, Col, ControlLabel, FlexboxGrid, Form, FormControl, FormGroup, HelpBlock, Loader, Navbar, Panel } from 'rsuite';
import { NodeService } from '../services/NodeService';
import SecretStorage from '../services/SecretCodeService';
import _ from 'lodash';

interface LoginProps {
    onSuccessfulLoginComplete: (success: boolean) => void;
}

interface LoginState {
    formValue: LoginFormValue,
    loading: boolean,
    loginError: string
}

interface LoginFormValue {
    secret: string;
}
// =======================================================================================================
export class LoginForm extends React.Component<LoginProps, LoginState> {
    private NodeService: NodeService;
    constructor(props: LoginProps) {
        super(props);
        this.state =
        {
            formValue:
            {
                secret: ''
            },
            loading: false,
            loginError: ''
        };
        this.NodeService = new NodeService()
    }

    handleSecret = async () => {
        this.setState({ loading: true })
        var resp = await this.NodeService.getOneTimeToken(this.state.formValue.secret);
        if (resp !== null && resp.code === 0) {
            SecretStorage.setUser(resp.result, resp.message);
            this.props.onSuccessfulLoginComplete(true);
        }
        else {
            this.setState({ loading: false, loginError: "Unable to login" })
        }
    }
    // --------------------------------------------------------------------------------------------------
    render() {
        return (
            <FlexboxGrid justify="space-between" style={{ marginLeft: "20px", marginRight: "20px", marginTop: 50 }}>
                <FlexboxGrid.Item componentClass={Col} colspan={24} lg={48} md={48}>
                    <Panel bordered shaded style={{ width: "100%" }} >
                        <div style={{ textAlign: "center" }} >
                            <h1 style={{ fontWeight: "normal" }}>Login</h1>

                        </div>
                        <Form style={{ marginTop: 10 }} layout="vertical" onChange={(formValue) => {
                            this.setState({ formValue: formValue as LoginFormValue });
                        }}>
                            <FormGroup>
                                <ControlLabel>Secret Code</ControlLabel>
                                <FormControl name="secret" type="password" />
                                <HelpBlock tooltip>Required</HelpBlock>
                            </FormGroup>

                        </Form>
                        <div style={{ textAlign: "center", marginTop: 5 }}>
                            {this.state.loading ? <Loader /> : <Button onClick={this.handleSecret} appearance="primary" >Submit</Button>}
                        </div>
                        {this.state.loginError.length ? <div style={{ color: "red", marginTop: 5 }}>{this.state.loginError}</div> : null}

                    </Panel>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        )
    }
}
// =======================================================================================================
