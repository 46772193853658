import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// import default style
import '../index.less';


function screenWidth(): number {
    return window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;
}

function screenHeight(): number {
    return window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;
}

var appRender = () => ReactDOM.render(
    <React.StrictMode>
        <App
            width={screenWidth()}
            height={screenHeight()}
        />
    </React.StrictMode>,
    document.getElementById('root')
);

// If the browser resized redraw everything
window.addEventListener('resize', appRender);
appRender();