import React from 'react';

import mscLogo from '../img/weblogo@2x.png';

import {
    Container, Header, Content, Footer,
    Nav, Navbar, Icon, Button, IconButton
} from 'rsuite';



import Home from './Home';
import SecretStorage from '../services/SecretCodeService';
import { LoginForm } from './Login';
import NavalFleetManager from './NavalFleetManager';

// =======================================================================================================
export interface AppProps {
    width: number;
    height: number;
}
// ------------------------------------------------------------------------------------------------------
export interface AppState {

    // User info
    user: any;
    userName: string | undefined | null;
}
// ------------------------------------------------------------------------------------------------------

// ------------------------------------------------------------------------------------------------------
export default class App extends React.Component<AppProps, AppState> {
    // --------------------------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------------------------
    constructor(props: AppProps) {
        super(props);

        this.state = {
            user: null,
            userName: undefined,
        };

    }
    // --------------------------------------------------------------------------------------------------
    public componentDidMount() {
        this.getUser();
    }
    // --------------------------------------------------------------------------------------------------

    async getUser() {
        var accessToken = SecretStorage.getAccessToken();
        if (accessToken !== null && accessToken !== undefined) {
            var isAuthorized = await SecretStorage.isAuthorized();
            if (isAuthorized) {
                var uname = SecretStorage.getUname();
                this.setState({ user: accessToken, userName: uname });
            }
        }
    }
    // --------------------------------------------------------------------------------------------------

    login = (res: boolean) => {
        //retrieve accessToken
        console.log("onLogin");
        this.getUser();
    }
    // --------------------------------------------------------------------------------------------------
    renderPage() {
        if (this.state.user === null || this.state.user === undefined)
            return <div className="main-body"><LoginForm onSuccessfulLoginComplete={this.login} /></div>

        else
            return <NavalFleetManager onAccessRevoked={this.handleLogoutMenu} height={this.props.height - 56 /* toolbar */ - 20 /* footer */} />;
    }
    // --------------------------------------------------------------------------------------------------

    handleLogoutMenu = () => {
        SecretStorage.logout();
        this.setState({ "user": undefined })
    }
    // --------------------------------------------------------------------------------------------------
    render() {
        var title = "Crewing Officer";
        return <Container style={{ height: this.props.height, width: this.props.width }}>
            <Container>
                <Header>
                    <Navbar appearance="inverse" className="app-header">
                        <Navbar.Header>
                            <img src={mscLogo} className="msc-logo" alt="msc-logo" />

                        </Navbar.Header>
                        <Navbar.Body>
                            <Nav style={{ marginTop: 20 }}>{title}</Nav>
                            <Nav pullRight>
                                <IconButton style={{ background: "transparent", marginTop: 10, marginRight: 10 }} onClick={this.handleLogoutMenu} icon={<Icon icon="exit" style={{ background: "transparent" }} />} >LogOut {this.state.userName}</IconButton>
                            </Nav>


                        </Navbar.Body>


                    </Navbar>
                </Header>
                <Content className="app-body">
                    {this.renderPage()}
                </Content>
                <Footer>©  2020 Electi, AI, Blockchain, Cryptography. </Footer>
            </Container>
        </Container>
    }
}
// =======================================================================================================
